<template>
    <div class="flex justify-center px-6 lg:px-0">
        <div class="w-full lg:w-4/12 pt-12">
            <div class="text-center mb-10">
                <h2 class="font-bold text-3xl">New password</h2>
            </div>
            <div class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert"
                v-if="validationErrors || error">
                <strong class="font-bold">Please fix the following errors:</strong>
                <ul v-for="(category, index) in validationErrors" :key="index">
                    <li v-for="(error, i) in category" :key="i">
                        {{ error }}
                    </li>
                </ul>
                <span v-if="error">{{ error }}</span>
            </div>
            <ValidationObserver ref="resetPasswordForm" v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(resetPassword)" method="post">
                    <div class="grid grid-cols-1 row-gap-4">
                        
                        <div>
                            <ValidationProvider rules="required|min:8" name="Password" vid="password"
                                v-slot="{ errors }">
                                <label class="form-lable">New Password</label>
                                <input v-model="form.password" class="form-input w-full"
                                    :class="{ 'border-red-400': errors.length }" type="password">
                                <p class="text-red-700 text-sm mt-1" v-if="errors.length">{{ errors[0] }}</p>
                            </ValidationProvider>
                        </div>
                        <div>
                            <ValidationProvider rules="required|confirmed:password" vid="confirmation"
                                name="Password confirmation" v-slot="{ errors }">
                                <label class="form-lable">Confirm Password</label>
                                <input v-model="form.password_confirmation" class="form-input w-full"
                                    :class="{ 'border-red-400': errors.length }" type="password">
                                <p class="text-red-700 text-sm mt-1" v-if="errors.length">{{ errors[0] }}</p>
                            </ValidationProvider>
                        </div>
                    </div>

                    <button class="btn bg-gray-600 my-10 flex justify-center" type="submit">
                        <span v-if="loading">
                            <loading-icon class="h-5 w-5 mr-4" />
                        </span>
                        <span v-else>Update</span>
                    </button>
                </form>
            </ValidationObserver>
        </div>
    </div>
</template>

<script>
import api from '@/api';

export default {
    name: 'CompleteAccount',
    data() {
        return {
            loading: false,
            form: {
                email: this.$route.query.email,
                token: this.$route.params.token,
                password: null,
                password_confirmation: null,
                device: 'spa'
            },
            validationErrors: null,
            error: null
        }
    },
    mounted() {

    },
    methods: {
        resetPassword() {
            this.loading = true;
            api.post('/auth/account/set-password', this.form).then((response) => {

                this.login({
                    email: response.data.email,
                    password: this.form.password,
                    device: 'spa',
                })


                // this.$store.dispatch('dashboard/bookings/fetchBookings')
                // this.$store.dispatch('user/fetchUnreadMessages', this.user.id)
            })
        },

        login(data) {
            this.loading = true
            this.$store.dispatch('auth/login', data)
                .then(response => {
                    if (response) {
                        window.Event.$emit('login');
                        this.$router.push({ name: 'register-profile-type' })
                        this.$store.dispatch('dashboard/bookings/fetchBookings')
                        this.$store.dispatch('user/fetchUnreadMessages', this.user.id)
                    }

                    this.startPendo();
                })
                .catch(errors => {
                    this.error = errors.response.data.message
                    this.$refs.loginForm.setErrors(errors.response.data.errors)
                    this.form.password = null
                })
                .finally(() => this.loading = false)
        },


    }
}

</script>